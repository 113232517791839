import { t } from 'i18next';
import { createSelector } from 'reselect';
import { getExternalRadiologistsList, getInternalRadiologistsList } from './radiologistsSelectors';

const selectActiveStudiesInput = state => state.dashboard.activeStudies;
const selectBodyPartListInput = state => state.dashboard.bodyPart.list;
const selectActiveStudiesFiltersInput = state => {
  return state.form && state.form.activeStudiesCardForm
    ? state.form.activeStudiesCardForm.values
    : undefined;
};
const selectRadiologistPerformanceInput = state =>
  state.dashboard.radiologistPerformance;
const selectFacilitiesInput = state => state.dashboard.facilities;

export const selectRadiologistPerformanceFiltersInput = state =>
  state.form.radiologistPerformanceCardForm
    ? state.form.radiologistPerformanceCardForm.values
    : {};

export const selectModalitiesInput = state => state.dashboard.modalities;

export const selectReportingRadiologistsInput = state =>
  state.dashboard.reportingRadiologists;

export const selectSentTosInput = state => state.dashboard.sentTos;

export const selectStudyDetailsInput = state => state.dashboard.studyDetails;

export const getUsersList = state => state.users.array || [];

export const selectQCBodyPartListInput = (state, section) => state.QCDashboard[section].bodyPartOptions;

export const selectModalityOptions = createSelector(
  selectModalitiesInput,
  modalities => {
    const modalityOptions = [{ value: 'ALL', label: t('table.all_modalities') }];
    try {
      if (modalities.list) {
        modalities.list.forEach(modality => {
          modalityOptions.push({ value: modality.name, label: modality.name });
        });
      }
      return modalityOptions;
    } catch (err) {
      return modalityOptions;
    }
  }
);

export const selectReportingRadiologistOptions = createSelector(
  selectReportingRadiologistsInput,
  reportingRadiologists => {
    const reportingRadiologistOptions = [
      { value: 'ALL', label: t('table.all_reporting_radiologists') }
    ];
    try {
      if (reportingRadiologists.list) {
        reportingRadiologists.list.forEach(reportingRadiology => {
          reportingRadiologistOptions.push({
            value: reportingRadiology.name,
            label: reportingRadiology.name
          });
        });
      }
      return reportingRadiologistOptions;
    } catch (err) {
      return reportingRadiologistOptions;
    }
  }
);

export const selectSentToOptions = createSelector(
  selectSentTosInput,
  sentTos => {
    const sentToOptions = [{ value: 'ALL', label: t('table.all_sent_to') }];
    try {
      if (sentTos.list) {
        sentTos.list.forEach(sentTo => {
          sentToOptions.push({ value: sentTo.name, label: sentTo.name });
        });
      }
      return sentToOptions;
    } catch (err) {
      return sentToOptions;
    }
  }
);

export const selectActiveStudies = createSelector(
  selectActiveStudiesFiltersInput,
  selectActiveStudiesInput,
  (filters, activeStudies) => {
    try {
      let filteredActiveStudiesList = activeStudies.list.filter(item => item);
      if (filters && filters.modality !== 'ALL') {
        filteredActiveStudiesList = filteredActiveStudiesList.filter(
          item => item.modality === filters.modality
        );
      }
      if (filters && filters.facility !== 'ALL') {
        filteredActiveStudiesList = filteredActiveStudiesList.filter(
          item => item.facilityId === filters.facility
        );
      }
      if (filters && filters.sentTo !== 'ALL') {
        filteredActiveStudiesList = filteredActiveStudiesList.filter(
          item => item.sentTo === filters.sentTo
        );
      }

      return Object.assign({}, activeStudies, {
        list: filteredActiveStudiesList
      });
    } catch (err) {
      return activeStudies;
    }
  }
);

export const selectFacilitiesWithStatus = createSelector(
  selectFacilitiesInput,
  facilities => {
    try {
      const facilityStatusIds = facilities.statusList.map(
        facilityStatus => facilityStatus.facilityId
      );
      const completedFacilitiesList = facilities.list.map(facility => {
        const facilityStatusIndex = facilityStatusIds.indexOf(facility.guid);
        if (facilityStatusIndex >= 0) {
          facility.isDeviceOnline = true;
          facility = {
            ...facility,
            ...facilities.statusList[facilityStatusIndex]
          };
        } else {
          facility.isDeviceOnline = false;
        }
        return facility;
      });

      return Object.assign({}, facilities, {
        list: completedFacilitiesList
      });
    } catch (err) {
      return facilities;
    }
  }
);

export const selectFacilityOptions = createSelector(
  selectFacilitiesInput,
  facilities => {
    const facilityOptions = [{ value: 'ALL', label: t('table.all_source_facilities') }];
    try {
      if (facilities.list) {
        facilities.list.forEach(facility => {
          facilityOptions.push({ value: facility.guid, label: facility.name });
        });
      }
      return facilityOptions;
    } catch (err) {
      return facilityOptions;
    }
  }
);

export const selectRadiologistPerformance = createSelector(
  selectRadiologistPerformanceInput,
  selectRadiologistPerformanceFiltersInput,
  (radiologistPerformance, radiologistPerformanceFilters) => {
    try {
      let totalCount = 0;
      const modalityCount = radiologistPerformance.list.reduce(
        (acc, curr, index) => {
          const currModalityCount = curr.modalityCount;
          const currKeys = Object.keys(currModalityCount);
          currKeys.forEach(key => {
            if (acc[key]) {
              acc[key] += currModalityCount[key];
            } else {
              acc[key] = currModalityCount[key];
            }
            totalCount += currModalityCount[key];
          });
          return acc;
        },
        {}
      );

      return Object.assign({}, radiologistPerformance, {
        list: radiologistPerformance.list,
        filters: radiologistPerformanceFilters,
        total: { radiologistName: 'Total', modalityCount, totalCount }
      });
    } catch (err) {
      return radiologistPerformance;
    }
  }
);

export const selectStudyDetails = createSelector(
  selectStudyDetailsInput,
  studyDetails => {
    try {
      const setStudyDetailsReportsTotal = list =>
        list.reduce(
          ({ studies, urgent, routine }, curr) => ({
            studies: studies + curr.studies,
            urgent: urgent + curr.urgent,
            routine: routine + curr.routine
          }),
          {
            studies: 0,
            urgent: 0,
            routine: 0
          }
        );

      return Object.assign({}, studyDetails, {
        totalReports: setStudyDetailsReportsTotal(studyDetails.reports)
      });
    } catch (err) {
      return Object.assign({}, studyDetails, {
        totalReports: {
          studies: 0,
          urgent: 0,
          routine: 0
        }
      });
    }
  }
);

export const selectRadiologistOptions = createSelector(
  getInternalRadiologistsList,
  getExternalRadiologistsList,
  (internalRad, externalRad) => {
    const radiologistOptions = [];
    try {
      internalRad.forEach(rad => {
        radiologistOptions.push({
          value: rad.id,
          label: rad.name
        });
      });
      externalRad.forEach(rad => {
        radiologistOptions.push({
          value: rad.id,
          label: rad.name
        });
      });
      return radiologistOptions;
    } catch (err) {
      return radiologistOptions;
    }
  }
);

export const selectUserOptions = createSelector(
  getUsersList,
  (usersList) => {
    const radiologistOptions = [];
    try {
      usersList.forEach(rad => {
        radiologistOptions.push({
          value: rad.id,
          label: rad.name
        });
      });
      return radiologistOptions;
    } catch (err) {
      return radiologistOptions;
    }
  }
);

export const selectQCBodyPartList = (section) => createSelector(
  (state) => selectQCBodyPartListInput(state, section),
  qcBodyPartList => {
    try {
      const qcBodyPartListOptions = [{
        value: 'ALL',
        label: 'All Body Part'
      }]
      qcBodyPartList.forEach(item => {
        qcBodyPartListOptions.push({
          value: item,
          label: item
        })
      });
      return qcBodyPartListOptions;
    } catch (err) {
      return [];
    }
  }
);

export const selectBodyPartList = createSelector(
  selectBodyPartListInput,
  (bodyPartList) => {
    try {
      const bodyPartListOptions = [{
        value: 'ALL',
        label: 'All Body Part'
      }]
      bodyPartList.forEach(item => {
        bodyPartListOptions.push({
          value: item,
          label: item
        })
      });
      return bodyPartListOptions;
    } catch (err) {
      return [];
    }
  }
);