import React, { useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { change, Field, reduxForm } from 'redux-form';
import { Row, Col } from 'antd';
import { bindActionCreators } from 'redux';
import {
  dateOptions,
  dateFilters,
  dateFilterStrings,
  bodyPartFilterOptions
} from '../../../store/constants/appConstants';
import { RADIOLOGIST_PERFORMANCE_CARD_FORM_NAME } from '../../../utils/form-helpers';
import { selectBodyPartList, selectFacilityOptions, selectModalityOptions } from '../../../store/selectors';
import { SelectOptions, ReduxState } from '../../../schemas';
import { getRadiologistPerformanceRequest } from '../../../store/actions';
import RadioGroupField from '../../../common/form-helpers/RadioGroupField';
import DateRangePickerField from '../../../common/form-helpers/DateRangePickerField';
import { t } from 'i18next';
import InputSelectField from '../../../common/form-helpers/InputSelectField';

function RadiologistPerformanceCardForm({
  radioDateOptions,
  modalityOptions,
  bodyPartOptions,
  resetField,
}) {
  return (
    <Row
      id='radiologist-performance-card-form'
      type='flex'
      align='middle'
      justify='end'
      gutter={[16, 12]}
    >
      {/* <Col xs={24} sm={24} md={8} lg={8} xl={7}>
        <Field
          name='view'
          labelStyle={{ margin: 0 }}
          placeholder='Select view'
          options={viewOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col>
      <Col xs={24} sm={24} md={8} lg={8} xl={7}>
        <Field
          name='facility'
          labelStyle={{ margin: 0 }}
          placeholder='Select facility'
          options={facilityOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col> */}
      {/* <Col xs={24} sm={24} md={8} lg={8} xl={5}>
        <Field
          name='dateFilter'
          placeholder='Select date'
          options={dateOptions}
          suffixIcon={<Icon type='calendar' />}
          labelStyle={{ margin: 0 }}
          width='100%'
          component={InputSelectField}
          onChange={event => changeDateFilter(event)}
        />
      </Col> */}
      <Col xs={24} sm={12} md={6}>
        <Field
          name='modality'
          labelStyle={{ margin: 0 }}
          placeholder={t('referral_patterns.select_modality')}
          options={modalityOptions}
          width='100%'
          component={InputSelectField}
        />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Field
          name='bodyPart'
          placeholder={t('dashboard.select_body_part')}
          options={bodyPartOptions}
          labelStyle={{ margin: 0 }}
          width='100%'
          component={InputSelectField}
        />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Field
          name='dateFilter'
          labelStyle={{ margin: 0 }}
          radioBtnType='outline'
          options={radioDateOptions}
          component={RadioGroupField}
          onChange={() => resetField('selectedDate')}
        />
      </Col>
      <Col xs={24} sm={12} md={6}>
        <Field
          name='selectedDate'
          labelStyle={{ margin: 0 }}
          width='100%'
          component={DateRangePickerField}
          // eslint-disable-next-line no-alert
          onChange={() => resetField('dateFilter')}
        />
      </Col>
    </Row>
  );
}

const mapStateToProps = (state) => {
  return {
    radioDateOptions: [dateOptions[0], dateOptions[1]],
    facilityOptions: selectFacilityOptions(state),
    modalityOptions: selectModalityOptions(state),
    bodyPartOptions: selectBodyPartList(state),
    initialValues: {
      view: 'RVU',
      facility: 'ALL',
      dateFilter: dateFilters.TODAY
    }
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getRadiologistPerformance: getRadiologistPerformanceRequest,
      resetField: fieldName =>
        change(RADIOLOGIST_PERFORMANCE_CARD_FORM_NAME, fieldName, '')
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: RADIOLOGIST_PERFORMANCE_CARD_FORM_NAME
  })(RadiologistPerformanceCardForm)
);
