import React from "react";
import { Col, Form, Row } from "antd";
import { Field, reduxForm } from "redux-form";
import InputSearchField from "../../../../common/form-helpers/InputSearchField";
import InputSelectField from "../../../../common/form-helpers/InputSelectField";
import { daysOption, modalityOptions, newUrgencyOptions } from "../../../../store/constants/appConstants";
import InputCheckboxGroupField from "../../../../components/FormParts/InputCheckboxGroupField";
import "./RouteFilterForm.less"

const RouteFilterForm = ({ facilityList, bodyPartOptions }) => {
    const renderFacilityOptions = (facilityList = []) => {
        return facilityList.map((facility) => {
            return { label: facility.name, value: facility.guid }
        })
    }

    return <Form className="route-filter-form">
        <Row gutter={[16, 16]}>
            <Col>
                <Field
                    name="search"
                    component={InputSearchField}
                    size="large"
                    placeholder="Search"
                    enterButton
                />

            </Col>
            <Col>
                <Field
                    name="days"
                    component={InputSelectField}
                    // size="large"
                    label={<span style={{ fontWeight: 600 }}>Select Day</span>}
                    mode="multiple"
                    options={daysOption}
                    placeholder="Monday"
                    style={{ width: '100%' }}
                    showArrow
                    enterButton
                />
            </Col>
            <Col>
                <Field
                    name="source_facility"
                    component={InputSelectField}
                    label={<span style={{ fontWeight: 600 }}>Source Facility</span>}
                    mode="multiple"
                    options={renderFacilityOptions(facilityList)}
                    placeholder="Source Facility"
                    style={{ width: '100%' }}
                    showArrow
                    enterButton
                />
            </Col>

            <Col>
                <Field
                    name="modality"
                    component={InputSelectField}
                    // size="large"
                    label={<span style={{ fontWeight: 600 }}>Modality</span>}
                    mode="multiple"
                    options={modalityOptions.filter((modality) => modality.value !== "ALL")}
                    placeholder="Modality"
                    style={{ width: '100%' }}
                    showArrow
                    enterButton
                />
            </Col>

            <Col>
                <Field
                    name="body_part"
                    component={InputSelectField}
                    label={<span style={{ fontWeight: 600 }}>Body Part</span>}
                    mode="multiple"
                    options={bodyPartOptions}
                    placeholder="Body Part"
                    style={{ width: '100%' }}
                    showArrow
                    enterButton
                />
            </Col>

            <Col>
                <Field
                    name="urgency"
                    component={InputCheckboxGroupField(newUrgencyOptions)}
                    isSelectionHide
                    label={<span style={{ fontWeight: 600 }}>Urgency</span>}
                />
            </Col>
        </Row>
    </Form>;
};

export default reduxForm({
    form: "routeFilterForm",
    destroyOnUnmount: false,
    keepDirtyOnReinitialize: true,
    enableReinitialize: true,
})(RouteFilterForm);
