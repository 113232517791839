import { createSelector } from "reselect";

export const selectRouteBodyPartListInput = (state) => state.route.bodyPartOptions;

export const selectRouteBodyPartList = () => createSelector(
    (state) => selectRouteBodyPartListInput(state),
    routeBodyPartList => {
        try {
            const routeBodyPartListOptions = [{
                value: 'ALL',
                label: 'All Body Part'
            }]
            routeBodyPartList.forEach(item => {
                routeBodyPartListOptions.push({
                    value: item,
                    label: item
                })
            });
            return routeBodyPartListOptions;
        } catch (err) {
            return [];
        }
    }
);