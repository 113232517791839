// @flow

import React from 'react';
import { Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import { confirmDeletion } from '../../components/confirmations';
import EditUserForm from './forms/EditUserForm';
import {
  editUserAction,
  getUserByIdAction,
  loadEditUserForm,
  deleteUserAction,
  getInternalFacilitiesAction,
  getUserLocationRequest,
  postUserSignatureUploadRequest
} from '../../store/actions';
import type { Facility, ReduxState, User } from '../../schemas';
import { editUserValuesToUser } from '../../api/transformers';
import { reset } from "redux-form"

type Props = {
  // eslint-disable-next-line react/no-unused-prop-types
  form: Object,
  user: User,
  affiliatedGroup: string,
  visible: boolean,
  load: boolean,
  loading: boolean,
  loadingFacilities: boolean,
  onCancel: () => void,
  editAction: (User, string) => void,
  deleteAction: string => void,
  // eslint-disable-next-line react/no-unused-prop-types
  loadAction: string => void,
  loadFacilities: () => void,
  loadForm: any => void,
  profileId: string,
  facilities: Array<Facility>,
  initialValues: Object
};

type State = {
  user: User,
  currentRole: ?string
};

export default connect(
  (state: ReduxState) => {
    return {
      form: state.form.editUserForm,
      user: state.users.current,
      load: state.loading.getUserById,
      loading: state.loading.updateUser,
      loadingFacilities: state.loading.facilities,
      affiliatedGroup: state.profile.appMetadata.affiliatedGroup,
      profileId: state.profile.id,
      facilities: state.facilities.internalArray,
      initialValues: state.initialForm.editUser,
      userLocation: state.location,
      profile: state.profile,
    }
  },
  {
    editAction: editUserAction,
    deleteAction: deleteUserAction,
    loadAction: getUserByIdAction,
    loadForm: loadEditUserForm,
    loadFacilities: getInternalFacilitiesAction,
    getUserLocation: getUserLocationRequest,
    postUserSignatureUploadRequest: postUserSignatureUploadRequest,
    reset
  }
)(
  class EditUser extends React.Component {
    state = {
      user: {},
      currentRole: undefined,
      signatureData: null,
      visibleSignatureModal: false,
      enableSubmitBtn: false,
    };


    static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.user.id !== prevState.user.id) {
        return { user: nextProps.user };
      }
      return null;
    }


    componentDidMount() {
      const { user } = this.state;
      const { loadFacilities, getUserLocation } = this.props;
      loadFacilities();
      // getUserLocation()
      // !this.state.azureBlobSAS && this.getSASForAzureBlob()
      if (Object.entries(user).length !== 0) {
        this.updateUser();
      }
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState, snapshot) {
      const {
        user: { id }
      } = this.state;
      if (prevState.user.id !== id) {
        this.updateUser();
      }
      if (prevProps.loading && !this.props.loading) {
        this.props.onCancel();
      }
    }

    updateUser = () => {
      const { user } = this.state;
      const { loadForm } = this.props;
      const form = {
        name: user.name,
        userType: user.userMetadata.userType,
        email: user.email,
        phoneNumber: user.userMetadata.phoneNumber,
        homeFacility: user.userMetadata.homeFacility,
        specialty: user.userMetadata.specialty,
        availableForAssignment: user.appMetadata.availableForAssignment || '1',
        allowed_to_delete_study:
          user.appMetadata.allowed_to_delete_study || '1',
        allowed_study_share_before_report: user.appMetadata.allowed_study_share_before_report || '0',
        admin: user.appMetadata.admin,
        title: user.userMetadata.title,
        credentials: user.userMetadata.credentials
      };
      loadForm(form);
    };

    onChangeUserType = value => this.setState({ currentRole: value });

    callbackFunction = (status,) => {
      status && this.props.reset("userSignatureForm")
    }

    handleSubmit = async values => {
      const { editAction, affiliatedGroup, user, facilities } = this.props;
      const data = editUserValuesToUser(values, affiliatedGroup, facilities, user);
      editAction(data, user.id, this.callbackFunction);
    };

    handleDelete = () => {
      const { user, deleteAction } = this.props;
      confirmDeletion(deleteAction, user.id, user.name, 'user');
    };

    initialValuesSignature(isForm) {
      const { user } = this.props

      if (user && Object.keys(user).length > 0) {
        const { userMetadata: { name, credentials, phoneNumber, title, userType, signature, signature_settings } } = user || {}
        if (signature_settings && Object.keys(signature_settings).length > 0) {
          const { auto_generated, digitally_signed, font_name, qualifications, signature_name, signature_text, designation, reporting_date_format, display_phone_number } = signature_settings
          return {
            title: title,
            name: name,
            credentials: credentials,
            phone_number: phoneNumber,
            designation: designation,
            auto_generated: auto_generated,
            signatureNew: signature,
            signature: signature,
            digitally_signed: digitally_signed,
            font_name: font_name,
            reporting_date_format: reporting_date_format,
            qualifications: qualifications || "",
            signature_name: signature_name,
            signature_text: signature_text,
            display_phone_number: display_phone_number
          }
        } else if (isForm) {
          return {
            auto_generated: true,
            font_name: "Inter"
          }
        } else {
          return {}
        }
      }
    }

    render() {
      const {
        visible,
        onCancel,
        loading,
        user,
        load,
        loadingFacilities,
        profileId,
        facilities,
        initialValues,
        signatureSetting,
        onClickDeleteSignature,
        onClickAddSignature
      } = this.props;
      if (Object.entries(user).length === 0 || load || loadingFacilities) {
        return (
          <div>
            <Modal
              visible={visible}
              title='Edit user'
              okText='Edit'
              footer={null}
              onCancel={onCancel}
              wrapClassName='lateral-modal'
            >
              <div style={{ textAlign: 'center' }}>
                <Spin size='large' />
              </div>
            </Modal>
          </div>
        );
      }
      let groupFacilities = [];
      if (user.appMetadata && user.appMetadata.approvedFacilities) {
        groupFacilities = facilities
          .filter((f: Facility) =>
            user.appMetadata.approvedFacilities.includes(f.guid)
          )
          .map((f: Facility) => f.name);
      }

      return (
        <div>
          <EditUserForm
            visible={visible}
            onCancel={onCancel}
            userLocation={this.props.userLocation}
            visibleSignatureModal={this.state.visibleSignatureModal}
            enableSubmitBtn={this.state.enableSubmitBtn}
            onSubmit={this.handleSubmit}
            onDelete={this.handleDelete}
            azureBlobSAS={this.props.azureBlobSAS}
            onChangeUserType={this.onChangeUserType}
            loading={loading}
            user={user}
            signatureSettings={signatureSetting || this.initialValuesSignature()}
            isActiveUser={user.id === profileId}
            initialValues={{ ...initialValues, groupFacilities }}
            onClickAddSignature={onClickAddSignature}
            onClickDeleteSignature={onClickDeleteSignature}
          />
        </div>
      );
    }
  }
);
