import { combineReducers } from 'redux';
import {
  GET_ACTIVE_STUDIES_SUCCESS,
  GET_ACTIVE_STUDIES_FAILURE,
  GET_FACILITIES_STATUS_SUCCESS,
  GET_FACILITIES_SUCCESS,
  GET_FACILITIES_FAILURE,
  GET_PROVIDER_PERFORMANCE_SUCCESS,
  GET_PROVIDER_PERFORMANCE_FAILURE,
  GET_OVERVIEW_SUCCESS,
  GET_OVERVIEW_FAILURE,
  GET_STUDY_DETAILS_SUCCESS,
  GET_STUDY_DETAILS_FAILURE,
  GET_RADIOLOGIST_PERFORMANCE_SUCCESS,
  GET_RADIOLOGIST_PERFORMANCE_FAILURE,
  GET_GROUP_DETAIL_SUCCESS,
  GET_GROUP_DETAIL_FAILURE,
  GET_MODALITIES_SUCCESS,
  GET_MODALITIES_FAILURE,
  GET_REPORTING_RADIOLOGISTS_SUCCESS,
  GET_REPORTING_RADIOLOGISTS_FAILURE,
  GET_SENT_TOS_SUCCESS,
  GET_SENT_TOS_FAILURE,
  GET_BODY_PART_LIST_SUCCESS
} from '../constants';
import { createReducer } from '../helpers';
import initialState from '../state';

const { dashboard } = initialState;

const modalities = createReducer(dashboard.modalities, {
  [GET_MODALITIES_SUCCESS]: (state, { data }) => ({
    ...state,
    list: data
  })
});

const reportingRadiologists = createReducer(dashboard.reportingRadiologists, {
  [GET_REPORTING_RADIOLOGISTS_SUCCESS]: (state, { data }) => ({
    ...state,
    list: data
  })
});

const sentTos = createReducer(dashboard.sentTos, {
  [GET_SENT_TOS_SUCCESS]: (state, { data }) => ({
    ...state,
    list: data
  })
});

const groupDetail = createReducer(dashboard.groupDetail, {
  [GET_GROUP_DETAIL_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data
  })
});

const activeStudies = createReducer(dashboard.activeStudies, {
  [GET_ACTIVE_STUDIES_SUCCESS]: (state, { data }) => ({
    ...state,
    list: data.list
  })
});

const facilities = createReducer(dashboard.facilities, {
  [GET_FACILITIES_SUCCESS]: (state, { data }) => ({
    ...state,
    list: data
  }),
  [GET_FACILITIES_STATUS_SUCCESS]: (state, { data }) => {
    const statusList = data.filter(item => item !== null);
    return {
      ...state,
      statusList
    };
  }
});

const providerPerformance = createReducer(dashboard.providerPerformance, {
  [GET_PROVIDER_PERFORMANCE_SUCCESS]: (state, { data }) => ({
    ...state,
    list: data
  })
});

const overview = createReducer(dashboard.overview, {
  [GET_OVERVIEW_SUCCESS]: (state, { data }) => ({
    ...state,
    ...data
  })
});

const studyDetails = createReducer(dashboard.studyDetails, {
  [GET_STUDY_DETAILS_SUCCESS]: (state, { data }) => {
    return {
      ...data
    };
  }
});

const radiologistPerformance = createReducer(dashboard.radiologistPerformance, {
  [GET_RADIOLOGIST_PERFORMANCE_SUCCESS]: (state, { data }) => ({
    ...state,
    list: data
  })
});

const bodyPart = createReducer(dashboard.bodyPart, {
  [GET_BODY_PART_LIST_SUCCESS]: (state, { data }) => ({
    ...state,
    list: data
  })
});

const errors = createReducer(dashboard.errors, {
  [GET_ACTIVE_STUDIES_FAILURE]: (state, { error }) => [...state, error],
  [GET_RADIOLOGIST_PERFORMANCE_FAILURE]: (state, { error }) => [
    ...state,
    error
  ],
  [GET_STUDY_DETAILS_FAILURE]: (state, { error }) => [...state, error],
  [GET_OVERVIEW_FAILURE]: (state, { error }) => [...state, error],
  [GET_PROVIDER_PERFORMANCE_FAILURE]: (state, { error }) => [...state, error],
  [GET_FACILITIES_FAILURE]: (state, { error }) => [...state, error],
  [GET_GROUP_DETAIL_FAILURE]: (state, { error }) => [...state, error],
  [GET_MODALITIES_FAILURE]: (state, { error }) => [...state, error],
  [GET_REPORTING_RADIOLOGISTS_FAILURE]: (state, { error }) => [...state, error],
  [GET_SENT_TOS_FAILURE]: (state, { error }) => [...state, error],
  [GET_STUDY_DETAILS_FAILURE]: (state, { error }) => [...state, error]
});

const dashboardReducer = combineReducers({
  modalities,
  reportingRadiologists,
  sentTos,
  groupDetail,
  activeStudies,
  facilities,
  providerPerformance,
  overview,
  studyDetails,
  radiologistPerformance,
  bodyPart,
  errors
});

export default dashboardReducer;
