// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Col, Divider, Empty, Form, Modal, Row, Switch, Typography } from 'antd';
import { Field } from 'redux-form';
import ConfirmRevokeAdminRights from '../partials/RevokeAdminRights';
import {
  InputPhoneNumberField,
  InputRadioButtonField,
  InputSelectField,
  InputTextField,
  InputCheckboxGroupFacilitiesField
} from '../../../components/FormParts';
import { RAD_SPECIALTY, USER_TYPE, YES_NO } from '../../../utils/filters';
import './BaseUserForm.less';
import type { Facility, ReduxState } from '../../../schemas';
import BasicSelectField from '../../../common/form-helpers/BasicSelectField';
import { t } from 'i18next';

const { Title } = Typography;

const styles = {
  title: {
    fontWeight: 500,
    fontSize: 14,
    color: 'rgba(0, 0, 0, 0.65)',
    marginBottom: 14
  },
  divider: {
    margin: '0px 0px 14px'
  },
};

type Props = {
  visible: boolean,
  title: string,
  // Text of the ok button
  okText: string,
  // What to do if the cancel button is pushed. It's required by the  Modal class
  onCancel: () => void,
  isMain: boolean,
  // Initial role for the user
  role: ?string,
  children: React.Node,
  facilities: Array<Facility>,
  selectedFacilities: Array<Facility>
};

/**
 * Contains the controls used to add or edit a user.
 */
export default connect(
  (state: ReduxState) => ({
    facilities: state.facilities.internalArray
  }),
  {}
)((props: Props) => {
  const {
    visible,
    onCancel,
    title,
    okText,
    isMain,
    children,
    facilities,
    selectedFacilities,
    onClickAddSignature,
    onClickDeleteSignature,
    userId,
    signatureSettings,
    azureBlobSAS
  } = props;
  const homeFacilities = facilities.map((f: Facility) => ({
    text: f.name,
    value: f.name
  }));
  let groupFacilities = [];
  if (selectedFacilities) {
    groupFacilities = facilities
      .filter((f: Facility) => selectedFacilities.includes(f.guid))
      .map((f: Facility) => f.name);
  }
  // Role is the userType of the edited user. We use it to decide if render
  // the specialty row
  // eslint-disable-next-line
  const [role, setRole] = React.useState <? string > (props.role);
  const [specialtyOpen, openSpecialty] = React.useState(false);
  const [homeFacilitiesOpen, openHomeFacilities] = React.useState(false);
  const [userTypeOpen, openUserType] = React.useState(false);
  const [visibleSignature, setVisibleSignature] = React.useState(signatureSettings && Object.keys(signatureSettings).length > 0 ? true : false);

  return (
    <Modal
      visible={visible}
      title={title}
      okText={okText}
      onCancel={onCancel}
      destroyOnClose={false}
      footer={children}
      wrapClassName='lateral-modal'
    >
      <Form layout='vertical'>
        <Row gutter={32}>
          <Col xs={24} sm={12}>
            <Row gutter={8} type='flex' style={{ flexWrap: 'nowrap' }}>
              <Col>
                <Field
                  style={{ width: 50 }}
                  name='title'
                  label={t('user_administration.field.title')}
                  placeholder='Dr.'
                  component={InputTextField}
                />
              </Col>
              <Col>
                <Field
                  name='name'
                  label={t('user_administration.field.name')}
                  placeholder='Name'
                  component={InputTextField}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='credentials'
              label={t('user_administration.field.credentials')}
              placeholder='MD, ARRT, FACR, etc'
              component={InputTextField}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='userType'
              label={t('user_administration.field.role')}
              placeholder='Please select...'
              component={BasicSelectField}
              options={USER_TYPE}
              onChange={value => setRole(value)}
            />
          </Col>
          <Col xs={24} sm={12}>
            {role && role.toLowerCase() === 'radiologist' && (
              <Field
                name='specialty'
                label={t('user_administration.field.specialty')}
                showSearch
                placeholder='Type to search or select'
                component={BasicSelectField}
                options={RAD_SPECIALTY}
                isMultiple
              />
            )}
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='email'
              label={t('user_administration.field.email_adress')}
              type='email'
              placeholder='mail@domain.com'
              component={InputTextField}
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='phoneNumber'
              label={t('user_administration.field.mobile_number')}
              component={InputPhoneNumberField}
              defaultCountry='sg'
            />
          </Col>
          <Col xs={24} sm={12}>
            <Field
              name='homeFacility'
              label={t('user_administration.field.home_facility')}
              component={BasicSelectField}
              options={homeFacilities}
            />
          </Col>
          <Col xs={24} sm={12}>
            {isMain ? (
              <ConfirmRevokeAdminRights />
            ) : (
              <Field
                name='admin'
                label={t('user_administration.field.admin_access')}
                props={{
                  className: 'admin-access'
                }}
                component={InputRadioButtonField(YES_NO)}
              />
            )}
          </Col>
          <Col xs={24} sm={12}>
            {isMain ? (
              <ConfirmRevokeAdminRights />
            ) : (
              <Field
                name='availableForAssignment'
                label={t('user_administration.field.available_assignment')}
                props={{
                  className: 'admin-access'
                }}
                component={InputRadioButtonField(YES_NO)}
              />
            )}
          </Col>
          <Col xs={24} sm={12}>
            {isMain ? (
              <ConfirmRevokeAdminRights />
            ) : (
              <Field
                name='allowed_to_delete_study'
                label={t('user_administration.field.allowed_delete_studies')}
                props={{
                  className: 'admin-access'
                }}
                component={InputRadioButtonField(YES_NO)}
              />
            )}
          </Col>
          <Col xs={24} sm={12}>
            {isMain ? (
              <ConfirmRevokeAdminRights />
            ) : (
              <Field
                name='allowed_study_share_before_report'
                label={t('user_administration.field.allowed_study_share_before_report')}
                props={{
                  className: 'admin-access'
                }}
                component={InputRadioButtonField(YES_NO)}
              />
            )}
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Row type='flex' justify='space-between'>
              <Col>
                {t('user_administration.field.signature')}
              </Col>
              <Col>
                <Switch checked={visibleSignature} onChange={() => setVisibleSignature(!visibleSignature)} />
              </Col>
            </Row>
          </Col>
          {visibleSignature && <Col span={24}>
            <Card title={t('user_administration.signature_preview')} className={`signature-card`} extra={signatureSettings && Object.keys(signatureSettings).length > 0 ? <div className='extra-btn-container'><Button icon='edit' onClick={onClickAddSignature} type='link' >{t("button.edit")}</Button><Button icon='delete' onClick={() => onClickDeleteSignature && onClickDeleteSignature(userId)} className='signature-delete-btn' type='link'>{t("button.delete")}</Button></div> : <Button icon='plus' onClick={onClickAddSignature} type='link'>{t('user_administration.add_signature')}</Button>} >
              {signatureSettings && Object.keys(signatureSettings).length > 0 ?
                <div id='imageCard' style={{ width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '200px', margin: 'auto', }}>
                  <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '12px' }}>
                    {signatureSettings.digitally_signed}
                  </Typography.Text>
                  <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.85)', fontFamily: signatureSettings.font_name }}>
                    {!signatureSettings.auto_generated ? <div><img src={signatureSettings.signature && signatureSettings.signature.preview ? signatureSettings.signature.preview : signatureSettings.signature + '?' + azureBlobSAS} width='200' alt='signature-preview' /></div> : (signatureSettings.name || null)}
                  </Typography.Text>
                  <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '14px', fontWeight: 600 }}>
                    {(signatureSettings.title || signatureSettings.name || signatureSettings.credentials) && signatureSettings.title + " " + signatureSettings.name + " " + signatureSettings.credentials + ""}
                  </Typography.Text>
                  <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.45)', fontSize: '12px', textTransform: 'capitalize' }}>
                    {signatureSettings.designation || ""}
                  </Typography.Text>
                  <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '10px' }}>
                    {signatureSettings.display_phone_number && signatureSettings.phone_number}
                  </Typography.Text>
                  <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '12px', textTransform: 'capitalize' }}>
                    {signatureSettings.qualifications}
                  </Typography.Text>
                  <Typography.Text style={{ color: 'rgba(0, 0, 0, 0.85)', fontSize: '12px' }}>
                    {signatureSettings.signature_text}
                  </Typography.Text>
                </div>
                : <div>
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<div className='empty-sign-text'><div>{t('user_administration.no_signature_available')}</div> <div>{t('user_administration.please_add_signature')}</div></div>} />
                </div>}
            </Card>
          </Col>}
        </Row>

        <Divider style={styles.divider} />
        <div className='facility-container'>
          <Title level={4} style={styles.title}>
            {t('user_administration.field.allow_user_studies_within_facilities')}
          </Title>
          <Field
            name='groupFacilities'
            label=''
            checkboxType='table'
            props={{ selected: groupFacilities }}
            component={InputCheckboxGroupFacilitiesField(homeFacilities)}
          />
        </div>
      </Form>
    </Modal>
  );
});
