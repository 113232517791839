import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import * as actions from '../actions/routeActions';
import { ADD_ROUTING_LOADING, DELETE_ROUTING_LOADING, EDIT_ROUTING_LOADING, GET_ROUTING_LIST_LOADING, GET_ROUTING_LOADING } from "../actions";
import { getCurrentUser } from "../selectors";
import { createRouteListAPI, createScheduleListAPI, deleteRouteByIdAPI, deleteScheduleByIdAPI, getBodyPartsListAPI, getRouteByIdAPI, getRouteListAPI, getScheduleListAPI, updateRouteByIdAPI, updateScheduleByIdAPI } from "../../api/endpoints/routes";
import uuid from 'uuid/v4';
import { errorNotification, successNotification } from "../../utils/notification-helpers";
import { pages } from "../../utils/routes";

function* getRouteListSagas() {
    yield put({ type: GET_ROUTING_LIST_LOADING, loading: true });
    try {
        const profile = yield select(getCurrentUser);
        const response = yield call(getRouteListAPI, profile);
        yield put({
            type: actions.GET_ROUTE_LIST_SUCCESS,
            data: response.data
        });

        yield put({ type: GET_ROUTING_LIST_LOADING, loading: false });
    } catch (error) {
        yield put({ type: GET_ROUTING_LIST_LOADING, loading: false });
    }
}

function* createRouteRequestSagas({ payload }) {
    yield put({ type: ADD_ROUTING_LOADING, loading: true });
    try {
        let params = {
            ...payload,
            id: uuid()
        }
        delete params.history
        const profile = yield select(getCurrentUser);
        const response = yield call(createRouteListAPI, profile, params);
        successNotification("Route created successfully!");
        yield put(actions.getRouteListRequestAction());
        yield put({ type: ADD_ROUTING_LOADING, loading: false });
        payload.history && payload.history.replace(pages.routes)
    } catch (error) {
        yield put({ type: ADD_ROUTING_LOADING, loading: false });
        errorNotification("Route creation failed!");
    }
}

function* updateRouteRequestSagas({ payload }) {
    yield put({ type: EDIT_ROUTING_LOADING, loading: true });
    try {
        let params = {
            ...payload
        }
        delete params.history
        const profile = yield select(getCurrentUser);
        const response = yield call(updateRouteByIdAPI, profile, params, params.id);
        successNotification("Route updated successfully!");
        yield put(actions.getRouteListRequestAction());
        yield put({ type: EDIT_ROUTING_LOADING, loading: false });
        payload.history && payload.history.replace(pages.routes)
    } catch (error) {
        yield put({ type: EDIT_ROUTING_LOADING, loading: false });
        errorNotification("Route update failed!");
    }
}

function* getSingleRouteSagas({ payload }) {
    yield put({ type: GET_ROUTING_LOADING, loading: true });
    try {
        const profile = yield select(getCurrentUser);
        const response = yield call(getRouteByIdAPI, profile, payload);
        yield put({
            type: actions.GET_SINGLE_ROUTE_SUCCESS,
            data: response
        });
        yield delay(500);
        yield put({ type: GET_ROUTING_LOADING, loading: false });
    } catch (error) {
        yield put({ type: GET_ROUTING_LOADING, loading: false });

    }
}

function* deleteRouteSagas({ payload }) {
    yield put({ type: DELETE_ROUTING_LOADING, loading: true });
    try {
        const profile = yield select(getCurrentUser);
        const response = yield call(deleteRouteByIdAPI, profile, payload.id);
        yield put(actions.getRouteListRequestAction());
        payload.callback && payload.callback(true);
        yield delay(500);
        yield put({ type: DELETE_ROUTING_LOADING, loading: false });
        successNotification("Route deleted successfully!");
    } catch (error) {
        errorNotification("Route deletion failed!");
        yield put({ type: DELETE_ROUTING_LOADING, loading: false });
        payload.callback && payload.callback(false);
    }
}

function* getScheduleListSagas() {
    // yield put({ type: GET_ROUTING_LIST_LOADING, loading: true });
    try {
        const profile = yield select(getCurrentUser);
        const response = yield call(getScheduleListAPI, profile);
        yield put({
            type: actions.GET_SCHEDULE_LIST_SUCCESS,
            data: response.data
        });
        // yield put({ type: GET_ROUTING_LIST_LOADING, loading: false });
    } catch (error) {
        // yield put({ type: GET_ROUTING_LIST_LOADING, loading: false });
    }
}

function* createScheduleRequestSagas({ payload }) {
    // yield put({ type: ADD_ROUTING_LOADING, loading: true });
    try {
        let params = {
            ...payload,
            id: uuid()
        }
        delete params.callback
        const profile = yield select(getCurrentUser);
        const response = yield call(createScheduleListAPI, profile, params);
        successNotification("Custom schedule saved successfully!");
        yield put(actions.getScheduleListRequestAction());
        payload.callback && payload.callback(true);
        // yield put({ type: ADD_ROUTING_LOADING, loading: false });
    } catch (error) {
        // yield put({ type: ADD_ROUTING_LOADING, loading: false });
        payload.callback && payload.callback(false);
        errorNotification("Failed to save schedule!");
    }
}

function* updateScheduleRequestSagas({ payload }) {
    // yield put({ type: EDIT_ROUTING_LOADING, loading: true });
    try {
        let params = {
            ...payload
        }
        delete params.callback
        const profile = yield select(getCurrentUser);
        const response = yield call(updateScheduleByIdAPI, profile, params, params.id);
        successNotification("Custom schedule saved successfully!");
        yield put(actions.getRouteListRequestAction());
        payload.callback && payload.callback(true);
        // yield put({ type: EDIT_ROUTING_LOADING, loading: false });
    } catch (error) {
        // yield put({ type: EDIT_ROUTING_LOADING, loading: false });
        errorNotification("Failed to save schedule!");
    }
}

function* deleteScheduleSagas({ payload }) {
    // yield put({ type: DELETE_ROUTING_LOADING, loading: true });
    try {
        const profile = yield select(getCurrentUser);
        const response = yield call(deleteScheduleByIdAPI, profile, payload.id);
        yield put(actions.getRouteListRequestAction());
        payload.callback && payload.callback(true);
        yield delay(500);
        // yield put({ type: DELETE_ROUTING_LOADING, loading: false });
        successNotification("Schedule deleted successfully!");
    } catch (error) {
        errorNotification("Schedule deletion failed!");
        // yield put({ type: DELETE_ROUTING_LOADING, loading: false });
        payload.callback && payload.callback(false);
    }
}

function* getBodyPartListSagas() {
    try {
        const profile = yield select(getCurrentUser);
        const response = yield call(getBodyPartsListAPI, profile);
        yield put(actions.getBodyPartOptionsSuccessAction(response.data));
    } catch (error) {
        console.error(error);
    }
}

export default function* routeSaga() {
    yield takeLatest(actions.GET_ROUTE_LIST_REQUEST, getRouteListSagas);
    yield takeLatest(actions.POST_CREATE_ROUTE_REQUEST, createRouteRequestSagas);
    yield takeLatest(actions.EDIT_ROUTE_REQUEST, updateRouteRequestSagas);
    yield takeLatest(actions.GET_SINGLE_ROUTE_REQUEST, getSingleRouteSagas);
    yield takeLatest(actions.DELETE_ROUTE_REQUEST, deleteRouteSagas);
    yield takeLatest(actions.GET_SCHEDULE_LIST_REQUEST, getScheduleListSagas);
    yield takeLatest(actions.POST_CREATE_SCHEDULE_REQUEST, createScheduleRequestSagas);
    yield takeLatest(actions.EDIT_SCHEDULE_REQUEST, updateScheduleRequestSagas);
    yield takeLatest(actions.DELETE_SCHEDULE_REQUEST, deleteScheduleSagas);
    yield takeLatest(actions.GET_BODY_PART_OPTIONS_REQUEST, getBodyPartListSagas);
}