/* eslint-disable no-shadow */
// @flow

import React, { useEffect, useState } from 'react';
import { Button, Col, Empty, Row, Table, Tooltip } from 'antd';
import { connect } from 'react-redux';
import moment from 'moment';
import { t } from 'i18next';
import RadiologistPerformanceCardForm from './form/radiologistPerformanceCardForm';
import CardWrapper from '../../common/CardWrapper';
import {
  selectRadiologistPerformance,
  selectModalityOptions
} from '../../store/selectors';
import { ReactComponent as CsvIcon } from '../../assets/images/csv-svgrepo-com.svg';
import { bindActionCreators } from 'redux';
import { getRadiologistPerformanceRequest } from '../../store/actions';

const styles = {
  headerCell: {
    backgroundColor: '#F7F7F7'
  }
};

function RadiologistPerformanceCard({
  radiologistPerformance,
  getRadiologistPerformanceRequest,
  loading
}) {
  // const viewFilter = radiologistPerformance.filters
  //   ? radiologistPerformance.filters.view
  //   : 'RVU';
  const [radiologiestData, setRadiologiestData] = useState([]);

  useEffect(() => {
    getRadiologistPerformanceRequest()
  }, [])

  useEffect(() => {
    setRadiologiestData(radiologistPerformance.list);
  }, [radiologistPerformance]);

  const availableModalities = Object.keys(
    radiologistPerformance.total.modalityCount
  );
  const modalityOptionsList = availableModalities.map(item => {
    return {
      title: item,
      key: item,
      dataIndex: `modalityCount.${item}`,
      align: 'center',
      width: 76,
      render: (text, record) => text || 0,
      onHeaderCell: () => ({ style: styles.headerCell })
    };
  });

  const columns = [
    {
      title: t('dashboard.reporting_radiologist'),
      key: 'radiologistName',
      dataIndex: 'radiologistName',
      width: 240,
      fixed: 'left',
      onHeaderCell: () => ({ style: styles.headerCell }),
      render: (text, record) => (
        <div style={{ wordBreak: 'break-word', width: '240px' }}>{text}</div>
      )
    },
    ...modalityOptionsList,
    {
      title: t('dashboard.total'),
      key: 'totalCount',
      dataIndex: 'totalCount',
      width: 80,
      align: 'center',
      fixed: 'right',
      onHeaderCell: () => ({ style: styles.headerCell })
    }
  ];

  const footerColumns = [
    {
      title: '',
      key: 'radiologistName',
      dataIndex: 'radiologistName',
      width: 240,
      fixed: 'left',
      onHeaderCell: () => ({ style: styles.headerCell })
    },
    ...modalityOptionsList,
    {
      title: t('dashboard.total'),
      key: 'totalCount',
      dataIndex: 'totalCount',
      width: 80,
      align: 'center',
      fixed: 'right',
      onHeaderCell: () => ({ style: styles.headerCell })
    }
  ];

  const tableProps = {
    columns,
    loading,
    dataSource: radiologistPerformance.list,
    scroll: { x: 720 },
    pagination: false
  };

  const footerTableProps = {
    columns: footerColumns,
    dataSource: [radiologistPerformance.total],
    loading: false,
    scroll: { x: 720 },
    pagination: false
  };

  const downloadCSV = (filename, arrayOfJson) => {
    const replacer = (key, value) => (value === null ? '' : value);
    const header = Object.keys(arrayOfJson[0]);
    let csv = arrayOfJson.map(row =>
      header
        .map(fieldName => JSON.stringify(row[fieldName], replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    csv = csv.join('\r\n');

    const link = document.createElement('a');
    link.setAttribute(
      'href',
      `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(csv)}`
    );
    link.setAttribute('download', filename);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {radiologiestData.length > 0 && (
        <Row
          justify='center'
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <Col>
            <Tooltip placement='left' title={t('dashboard.download_csv')}>
              <Button
                type='default'
                ghost
                style={{ border: 'none', boxShadow: 'none', padding: 0 }}
                className='btn'
                onClick={() => {
                  if (radiologiestData.length > 0) {
                    downloadCSV(
                      `modality-count-radiology-performance${moment().format()}.csv`,
                      radiologiestData
                    );
                  }
                }}
              >
                <CsvIcon />
              </Button>
            </Tooltip>
          </Col>
        </Row>
      )}
      <CardWrapper
        headStyle={{ paddingTop: 10, paddingBottom: 10 }}
        title={t('dashboard.radiologist_performance')}
        extra={<RadiologistPerformanceCardForm />}
      >
        <Table
          className='table-dashboard-radiologist-performance'
          {...tableProps}locale={{
            emptyText: (
              <Empty
                description={t("table.no_data")}
              />
            ),
          }}
        />
        {radiologistPerformance.total &&
        radiologistPerformance.total.totalCount !== 0 ? (
          <Table
            className='table-dashboard-radiologist-performance table-dashboard-radiologist-performance--footer'
            {...footerTableProps}locale={{
              emptyText: (
                <Empty
                  description={t("table.no_data")}
                />
              ),
            }}
          />
        ) : null}
      </CardWrapper>
    </>
  );
}

const mapStateToProps = (state) => {
  const { loading } = state;
  return {
    radiologistPerformance: selectRadiologistPerformance(state),
    modalityOptions: selectModalityOptions(state),
    loading: loading.radiologistPerformance
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({ getRadiologistPerformanceRequest }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(RadiologistPerformanceCard);
