export * from './dashboardSelectors';

export * from './facilitySelectors';

export * from './groupSelectors';

export * from './radiologistsSelectors';

export * from './physicianSelectors';

export * from './dashboardSelectors';

export const getCurrentUser = state => state.profile || [];
export const getUsersLastSearch = state => state.users.currentSearch;
export const getAddress = state => state.profile.azure;
export const getPayment = state => state.payments;
export const getQCDashboard = state => state.QCDashboard;
